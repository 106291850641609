import "../css/hightex-style.css";
import 'bootstrap/dist/css/bootstrap.css';
import { Outlet } from "react-router-dom";

const Home = () => {
    return <><div id="home"  className="home">
    <div>
       <div className="overlay"></div>
       <section className="cd-hero">
                <li className="selected">
                   <div className="heading">
                      <h1>Hightechx<span> Solutions</span></h1>
                      <span>Innovative and attractive web and mobile based solutions for guaranteed business success.</span>
                   </div>
                   <div className="cd-full-width first-slide">
                      <div className="container">
                         <div className="row">
                            <div className="col-md-12">
                               <div className="content first-content">
                                  <h4>Let’s Talk More About hightechx solutions</h4>
                                  <p>Hightechx is one of the rapidly growing software solutions company in Sri Lanka. We strive to provide you with the best solutions to your business system needs. As our client, we place great emphasis and focus on your problems and goals and develop solutions that best fits your needs. We offer strong and effective solutions to your business with an enduring impact. We think of ourselves as your long-term partner.</p>
                                  <p>At Hightechx solutions, we have quality-minded staff, consisting of consultants, graphic artists, designers, developers and programmers, who have the abilities and experience to create successful solutions for your needs. We are confident that you will be satisfied with our quality service.</p>
                                  <div className="primary-button"><a>Discover More</a></div>
                               </div>
                            </div>
                         </div>
                      </div>
                   </div>
                </li>
       </section>

    </div>

 </div>
 </>
  };
  
export default Home;