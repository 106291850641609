import "../css/hightex-style.css";
import 'bootstrap/dist/css/bootstrap.css';
import { useLocation, Outlet, Link } from "react-router-dom";
import CastForEducationIcon from '@mui/icons-material/CastForEducation';

const Layout = () => {
    const pathname = useLocation().pathname
    console.log(pathname)
    return <><div id="layout" className="layout">
    <div>
       <link rel="shortcut icon" href="http://www.hightechx.lk/img/logo/favicon.png" />
       <link rel="apple-touch-icon" href="apple-touch-icon.png" />
       <link rel="stylesheet" href="../css/bootstrap.min.css"/>
       <div className="overlay"></div>
       <section className="cd-hero">
          <div className="cd-slider-nav">
             <nav>
                <span className="cd-marker item-1"></span>
                <ul>
                   <li id="1" className={pathname === '/' ?"selected" :""}>
                      <a href="/">
                         <div className="image-icon padding6">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="home" className="svg-inline--fa fa-home fa-w-18 header-icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                               <path fill="currentColor" d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z"></path>
                            </svg>
                         </div>
                         <h6>Welcome</h6>
                      </a>
                   </li>
                   <li id="2" className={pathname === '/aboutus' ?"selected" :""}>
                      <Link to="/aboutus">
                         <div className="image-icon padding6">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="users" className="svg-inline--fa fa-users fa-w-20 header-icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                               <path fill="currentColor" d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6 40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z"></path>
                            </svg>
                         </div>
                         <h6>About Us</h6>
                      </Link>
                   </li>
                   <li id="4" className={pathname.startsWith('/projects') || pathname === '/projects' ?"selected" :""}>
                      <a href="/projects">
                         <div className="image-icon padding6">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="briefcase" className="svg-inline--fa fa-briefcase fa-w-16 header-icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                               <path fill="currentColor" d="M320 336c0 8.84-7.16 16-16 16h-96c-8.84 0-16-7.16-16-16v-48H0v144c0 25.6 22.4 48 48 48h416c25.6 0 48-22.4 48-48V288H320v48zm144-208h-80V80c0-25.6-22.4-48-48-48H176c-25.6 0-48 22.4-48 48v48H48c-25.6 0-48 22.4-48 48v80h512v-80c0-25.6-22.4-48-48-48zm-144 0H192V96h128v32z"></path>
                            </svg>
                         </div>
                         <h6>Projects</h6>
                      </a>
                   </li>
                   <li id="5" className={ pathname === '/trainings' ?"selected" :""}>
                      <a href="/trainings">
                         <div className="image-icon padding6">
                            <CastForEducationIcon className="mui-icon"/>
                         </div>
                         <h6>Trainings</h6>
                      </a>
                   </li>
                   <li id="5" className={pathname === '/contactus' ?"selected" :""}>
                      <a href="/contactus">
                         <div className="image-icon padding8">
                            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="address-card" className="svg-inline--fa fa-address-card fa-w-18 header-icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                               <path fill="currentColor" d="M528 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm0 400H48V80h480v352zM208 256c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm-89.6 128h179.2c12.4 0 22.4-8.6 22.4-19.2v-19.2c0-31.8-30.1-57.6-67.2-57.6-10.8 0-18.7 8-44.8 8-26.9 0-33.4-8-44.8-8-37.1 0-67.2 25.8-67.2 57.6v19.2c0 10.6 10 19.2 22.4 19.2zM360 320h112c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8H360c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8zm0-64h112c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8H360c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8zm0-64h112c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8H360c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8z"></path>
                            </svg>
                         </div>
                         <h6>Contact Us</h6>
                      </a>
                   </li>
                </ul>
                </nav>
                </div>
                </section>
                </div>
                </div>
                <Outlet />

                </>;
  };
  
  export default Layout;