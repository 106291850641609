import React from 'react';
import { useState, useEffect } from 'react';
import { SESClient, SendEmailCommand } from "@aws-sdk/client-ses"; // ES Modules import
import Alert from '@mui/material/Alert';

const submit= async (request) => {
   console.log('submitting', request)
   try{
         const config = {
            region: "us-west-2",
            apiVersion: "2010-12-01",
            signatureVersion: 'v3',
            credentials: {
            accessKeyId:'AKIATBQQF7PSPB4XZ4DM',
            secretAccessKey: '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',
            region1: "us-west-2"
            },
         }
      const client = new SESClient(config);
      const input = {
      Source: "admin@hightechx.lk",
      Destination: {
         ToAddresses: [
            "admin@hightechx.lk"
         ]
      },
      Message: {
         Subject: {
            Data: "JavaFS-SE01-" + `[${request.mobileNo}][${request.name}]`
         },
         Body: {
            Html: {
            Data: "Hi, <br>Please refer below details. <br><br>" +
                  `Name : ${request.name} <br>` + 
                  `Email : ${request.email} <br>` + 
                  `Contact No : ${request.mobileNo} <br>` + 
                  (request.alCompleted? `Physics : ${request.alPhysics}` : "")  + "<br>" + 
                  (request.alCompleted? `Maths : ${request.alMaths}` : "")  + "<br>" + 
                  (request.alCompleted? `IT : ${request.alIt}` : "")  + "<br>" + 
                  (request.degree? `University : ${request.university}` : "")  + "<br>" + 
                  (request.degree? `Degree Name : ${request.degreeName}` : "")  + "<br><br>" + 
                  `Message : ${request.message}`
            }
         }
      }
      };
      const command = new SendEmailCommand(input);
      const response = await client.send(command);
      console.log(response)
      // { // SendEmailResponse
      //   MessageId: "STRING_VALUE", // required
      // }
      return true
   } catch(e){
      return false
   }
}

const validate= (request) => {
   console.log(request)
   const errors = []
   var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
   var namePattern = /^[a-zA-Z\s-]+$/;
   var phoneNoPattern = /^\d{10}$/;
   if(request.email.trim() === '' || request.name.trim() === '' || request.mobileNo.trim() === '') {
      errors.push('Please enter name, email and mobile No.')
   }
   if(!request.email.match(validRegex)){
      errors.push('Invalid email address. (eg: abc@XXX.XXX)')
   }
   if(!request.name.match(namePattern)){
      errors.push('Invalid Name.')
   }
   if(!request.mobileNo.match(phoneNoPattern)){
      errors.push('Invalid mobileNo. (eg: 07XX XXXXXX)')
   }
   if(!request.alCompleted && !request.degree) {
      errors.push('Yous should be either graduate/ undergraduate or AL completed.')
   }
   if(request.alCompleted && (request.alMaths==='' && request.alPhysics==='' && request.alIt==='')) {
      console.log(request)
      errors.push('Please select AL results for either Maths, IT or Physics.')
   }
   if(request.degree && (request.degreeName==='' || request.university==='')) {
      errors.push('Please enter the degree and the university.')
   }
   if(request.success) {
      errors.push('You have already sent your details. We will get back soon.')
   }
   return errors
}

const Trainings = () => {
   const [request, setRequest] = useState({
      email:'',
      name: '',
      mobileNo: '',
      alMaths: '',
      alPhysics: '',
      alIt: '',
      degreeName: '',
      university: '',
      message: '',
      alCompleted: true,
      degree: false,
      success:  false
    });
    const [validationErrors, setValidationErrors] = useState([]);

const setAl = (value) => {
   console.log(value)
   setRequest({...request, alCompleted: value})
}
const setDegree = (value) => {
   console.log(value)
   setRequest({...request, degree: value})
}
const setAlMaths = (value) => {
   console.log(value)
   setRequest({...request, alMaths: value})
}
const setAlIT = (value) => {
   console.log(value)
   setRequest({...request, alIt: value})
}
const setALPhysics = (value) => {
   console.log(value)
   setRequest({...request, alPhysics: value})
}

const sendEmail = async () => {
   console.log(request)
   const validationErrors = validate(request);
   
   if(validationErrors.length !== 0) {
      console.log(validationErrors)
      setValidationErrors(validationErrors)
   } else {
      const status = await submit(request)
      console.log(status)
      if (status) {
         setRequest({...request, success: status})
         setValidationErrors([])
      } else {
         setValidationErrors(['Server error. Please contact our mobile no for more details..'])
      }
   }
}

    return <><div id="trainings" className="trainings"><div>
                         <div className="overlay"></div>
             <section className="cd-hero">
                        <div className="heading">
                            <h1>Trainings</h1>
                            <span>We offer below trainings for selected candidates</span>
                         </div>
                         <div className="cd-half-width fivth-slide">
                            <div className="container">

                               <div className="row">
                                  <div className="col-md-12">
                                     <div className="content fivth-content">
                                        <div className="row">
                                        <ul  className="course-list">
                                        <h2 className="course">Practical Java Full Stack Software Engineering</h2>
                                        <li>Software Engineering fundemendals</li>
                                        <li>Java Programming</li>
                                        <li>Database Management System</li>
                                        <li>Front End Development</li>
                                        <li>Mobile Development (React Native)</li>
                                        <li>Rest API</li>
                                        <li>Cloud Concepts</li>
                                        <li>Other Main Fundementals</li>
                                        </ul>
                                           <div className="col-md-4">
                                              <div className="left-info">
                                                 <p><span className="who-apply">Who can apply :</span> Graduate or undergraduate from any government univerity or Minimum B pass in A/L Maths/Physics/ IT subjects</p>
                                                 <p><span className="who-apply">What we offer :</span> Main objective of this course is to enhance the software engineering practical knowledge of the participants to match the industry requirements. So you will have many practical sessions while studying the course content.</p>
                                                 <p>If you like to participate in this course please submit the details or contact us through below email address, we will get back to you soon.</p>
                                                 <p><span className="who-apply">Other benifits :</span></p>
                                                 <ul  className="benifits">
                                                    <li>100 % Scholarships: 4</li>
                                                    <li>50 % Scholarships: 10</li>
                                                    <li>25 % Scholarships: 10</li>
                                                    <li>Job Offer for selected individuals</li>
                                                 </ul>
                                                 <p><span className="who-apply">Payment :</span></p>
                                                 <ul  className="benifits">
                                                    <li>Course Fee: 50000</li>
                                                    <li>4 installments</li>
                                                    <li>15% Discount if one time payment</li>
                                                 </ul>
                                                 <br/>
                                                 <p>
                                                    <em className="contacts">
                                                       <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="envelope" className="svg-inline--fa fa-envelope fa-w-16 small-icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                          <path fill="currentColor" d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"></path>
                                                       </svg>
                                                       Email: admin@hightechx.lk 
                                                    </em>
                                                    <br/>
                                                    <em className="contacts">
                                                       <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone-alt" className="svg-inline--fa fa-phone-alt fa-w-16 small-icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                          <path fill="currentColor" d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path>
                                                       </svg>
                                                       +94770 269 955 
                                                    </em>
                                                    <br/>
                                                    <em className="contacts">
                                                       <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="map-marker-alt" className="svg-inline--fa fa-map-marker-alt fa-w-12 small-icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                                          <path fill="currentColor" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path>
                                                       </svg>
                                                       101/01 , Piliyandala, Mavittara
                                                    </em>
                                                    </p>
                                                 <ul className="social-icons"><i><a href="#"><i className="fa fa-facebook"></i></a></i><i><a href="#"><i className="fa fa-twitter"></i></a></i><i><a href="#"><i className="fa fa-linkedin"></i></a></i><i><a href="#"><i className="fa fa-rss"></i></a></i><i><a href="#"><i className="fa fa-behance"></i></a></i></ul>
                                              </div>
                                           </div>
                                           <div className="col-md-8">
                                              <div className="row">
                                                    <div className="col-md-12">
                                                      <div className="submisstion-status">
                                                         {request.success && <Alert severity="success">Successfully submitted your details. We will get back within 48 hours.</Alert>}
                                                         {(validationErrors.length !== 0)&& <Alert severity="error"><ul>{validationErrors.map(item => <li key={item}>{item}</li>)}</ul></Alert>}
                                                      </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                       <fieldset><input name="name" type="text" className="form-control" id="name" placeholder="Your Name" required="" onChange={(event)=> {setRequest({...request, name: event.target.value})}}/></fieldset>
                                                    </div>
                                                    <div className="col-md-12">
                                                       <fieldset><input name="email" type="email" className="form-control" id="email" placeholder="Email" required="" onChange={(event)=> {setRequest({...request, email: event.target.value})}}/></fieldset>
                                                    </div>
                                                    <div className="col-md-12">
                                                       <fieldset><input name="mobileNo" type="email" className="form-control" id="mobileNo" placeholder="MobileNo" required="" onChange={(event)=> {setRequest({...request, mobileNo: event.target.value})}}/></fieldset>
                                                    </div>
                                                    <div className="col-md-6">
                                                      <input type="checkbox" id="alCompleted" name="alCompleted" value={request.alCompleted} checked={request.alCompleted}  onChange={(event)=>{} } onClick={(event)=> {setAl(event.target.checked)}}/><label className="form-label"> AL Completed</label>
                                                    </div>
                                                    <div className="col-md-6">
                                                      <input type="checkbox" id="degreeUndergrad" name="degreeUndergrad" value={request.degree}  checked={request.degree}  onChange={(event)=>{} } onChange={(event)=> {setDegree(event.target.checked)}}/><label className="form-label"> Degree Holder/ undergraduate</label>
                                                    </div>
                                                    {request.alCompleted && <>
                                                    <div className="col-md-6">
                                                      <select name="alMaths" id="alMaths"  className="form-select" placeholder="AL Maths" value={request.alMaths}  onChange={(event)=> {setAlMaths(event.target.value)}}>
                                                         <option value="A">A</option>
                                                         <option value="B">B</option>
                                                         <option value="C">C</option>
                                                         <option value="S">S</option>
                                                         <option value="">AL Maths results</option>
                                                      </select>
                                                    </div>
                                                    <div className="col-md-6">
                                                      <select name="alPhysics" id="alPhysics"  className="form-select" placeholder="AL Physics" value={request.alPhysics}  onChange={(event)=>{setALPhysics(event.target.value)} } >
                                                         <option value="A">A</option>
                                                         <option value="B">B</option>
                                                         <option value="C">C</option>                                                         
                                                         <option value="S">S</option>
                                                         <option value="">AL Physics results</option>
                                                      </select>
                                                    </div>
                                                    <div className="col-md-6">
                                                      <select name="alIt" id="alIt"  className="form-select" placeholder="AL IT" value={request.alIt}   onChange={(event)=>{setAlIT(event.target.value)} }>
                                                         <option value="A">A</option>
                                                         <option value="B">B</option>
                                                         <option value="C">C</option>
                                                         <option value="S">S</option>
                                                         <option value="">AL IT results</option>
                                                      </select>
                                                    </div> </>}
                                                    {request.degree && <>
                                                    <div className="col-md-12">
                                                       <fieldset><input name="university" rows="6" className="form-control" id="university" placeholder="University" required="" onChange={(event)=> {setRequest({...request, university: event.target.value})}}></input></fieldset>
                                                    </div>                                                
                                                    <div className="col-md-12">
                                                       <fieldset><input name="degreeName" rows="6" className="form-control" id="degreeName" placeholder="Degree" required="" onChange={(event)=> {setRequest({...request, degreeName: event.target.value})}}></input></fieldset>
                                                    </div></>}
                                                    <div className="col-md-12">
                                                       <fieldset><textarea name="message" rows="6" className="form-control" id="message" placeholder="Message" required="" onChange={(event)=> {setRequest({...request, message: event.target.value})}}></textarea></fieldset>
                                                    </div>
                                                    <div className="col-md-12">
                                                       <fieldset><button type="submit" id="form-submit" className="btn" onClick={sendEmail}>Send Message</button></fieldset>
                                                    </div>
                                              </div>
                                           </div>
                                        </div>
                                     </div>
                                  </div>
                               </div>
                            </div>
                         </div>
             </section>
             </div>
             </div>
       </>;
  };
  
  export default Trainings;