import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/layout";
import Home from "./pages/home";
import Aboutus from "./pages/aboutus";
import Contactus from "./pages/contactus";
import Projects from "./pages/projects";
import Trainings from "./pages/trainings";
import NoPage from "./pages/nopage";
import FmsPortal from "./pages/fmsportal";

export default function App() {
  return (
   <> <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="aboutus" element={<Aboutus />} />
          <Route path="projects" element={<Projects />} />
          <Route path="projects/fmsportal" element={<FmsPortal />} />
          <Route path="contactus" element={<Contactus />} />
          <Route path="trainings" element={<Trainings />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
    <div className="footer">
      <footer>
          <p>Copyright © 2019 Hightechx Software Solutions PVT Ltd. | Designed by <em>Hightechx Solutions</em></p>
      </footer>
      </div>
    </>
  );
}
