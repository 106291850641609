const Aboutus = () => {
    return <><div id="aboutus"  className="aboutus">
          <div>
             <div className="overlay"></div>
             <section className="cd-hero">
                         <div className="heading">
                            <h1>About Us</h1>
                            <span>&nbsp;</span>
                         </div>
                         <div className="cd-half-width second-slide">
                            <div className="container">
                               <div className="row">
                                  <div className="col-md-12">
                                     <div className="content second-content">
                                        <div className="row">
                                           <div className="col-md-7 left-image"><img src={require('../img/left-about-image.jpg')}/></div>
                                           <div className="col-md-5">
                                              <div className="right-about-text">
                                                 <h4>Who we are?</h4>
                                                 <p>We are a professional software, website development company that endeavor on highly proficient, timely delivered and cost effective software, website development services.</p>
                                                 <div className="primary-button"><a>Discover More</a></div>
                                              </div>
                                           </div>
                                        </div>
                                        <div className="row">
                                           <div className="col-md-7">
                                              <div className="left-about-text">
                                                 <h4>What we do?</h4>
                                                 <p>We are highly experienced in offering software solutions and project management. Some of our services include System Integration &amp; Software Development, Client server application, E-commerce software development, Content management development, Web application development, Database application development. In a short span of time Hightechx Software Solutions has developed business relationship with a reputed clientele. Today, we provide services, support and solutions to clients in various Industry verticals. A few from the industry verticals that we work closely are Government Organizations, Factories, cab/taxi services.</p>
                                                 <div className="primary-button"><a>Discover More</a></div>
                                              </div>
                                           </div>
                                           <div className="col-md-5 right-image"><img src={require('../img/right-about-image.jpg')}/></div>
                                        </div>
                                     </div>
                                  </div>
                               </div>
                            </div>
                         </div>
             </section>

          </div>
       </div>
       </>;
  };
  
  export default Aboutus;