import React from 'react';
import YoutubeEmbed from './youtubeEmbed';



const FmsPortal = () => {


    return <><div id="fmsPortal" className="trainings"><div>
                         <div className="overlay"></div>
               <section className="cd-hero">
                        <div className="heading">
                            <h1>FMS Portal - Overview</h1>
                         </div>
                            <YoutubeEmbed embedId="LfAxLpi0jek" />     
             </section>
             <section className="cd-hero">
                        <div className="heading">
                            <h1>FMS Portal - Introduction</h1>
                         </div>
                            <YoutubeEmbed embedId="hysw1ijCqoA" />     
             </section>
             </div>
             </div>
       </>;
  };
  
  export default FmsPortal;