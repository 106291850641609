import "../css/hightex-style.css";

const Projects = () => {
    return <><div id="projects" className="projects">
          <div>
             <div className="overlay"></div>
             <section className="cd-hero">
                <div className="heading">
                <h1>Our projects</h1>
                <span>Here you can check our recent projects</span>
                </div>
                         <div className="cd-half-width fourth-slide">
                            <div className="container">
                               <div className="row">
                                  <div className="col-md-12">
                                     <div className="content fourth-content">
                                        <div className="row">
                                           <div className="col-md-3">
                                              <div className="project-item"><a><img src={require('../img/govfactory-logo.gif')}/></a></div>
                                              
                                                <a href="/projects/fmsportal" className="link">
                                                   <h6><span>Factory Management System</span></h6>
                                                </a>
                                           </div>
                                           <div className="col-md-3">
                                              <div className="project-item59"><img src={require('../img/thirikkale-logo.png')}/></div>
                                              <span>Vehicle Reservation System</span>
                                           </div>
                                           <div className="col-md-3">
                                              <div className="project-item34"><img src={require('../img/rotariseals-logo.png')}/></div>
                                              <span>Content Management System</span>
                                           </div>
                                           <div className="col-md-3">
                                              <div className="project-item70"><a><img src={require('../img/govfactory.gif')}/></a></div>
                                              <span>Factory Management System</span>
                                           </div>
                                        </div>
                                     </div>
                                  </div>
                               </div>
                            </div>
                         </div>
             </section>
          </div>
       </div>
       </>;
  };
  
  export default Projects;